/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-table-column-setting {
  width: auto;
}
.ant-pro-table-column-setting-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
}
.ant-pro-table-column-setting-overlay .ant-popover-inner-content {
  padding: 12px 0;
}
.ant-pro-table-column-setting-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.ant-pro-table-column-setting-list-title {
  color: #8c8c8c;
  font-size: 12px;
}
.ant-pro-table-column-setting-list-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 16px;
}
.ant-pro-table-column-setting-list-item-option {
  display: none;
  float: right;
  cursor: pointer;
}
.ant-pro-table-column-setting-list-item-option > span > i.anticon {
  color: #038fde;
}
.ant-pro-table-column-setting-list-item-option > span + span {
  margin-left: 8px;
}
.ant-pro-table-column-setting-list-item:hover {
  background-color: #e6faff;
}
.ant-pro-table-column-setting-list-item:hover .ant-pro-table-column-setting-list-item-option {
  display: block;
}
.ant-pro-table-column-setting-list .ant-checkbox-wrapper {
  flex: 1;
  margin: 0;
}
.ant-pro-table-column-setting-list .ant-checkbox-wrapper :last-child {
  margin-bottom: 0;
}
