.editable-table-container .ant-form-item-control.has-error {
  top: -8px !important;
}
.editable-table-container .ant-input-number {
  width: 100%;
}
.editable-table-icon {
  font-size: 16px !important;
  margin-right: 10px;
}
.editable-table-icon :last-child {
  margin-right: 0;
}
.editable-table-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.editable-table-footer .editable-table-btn-wrap {
  align-items: center;
  display: flex;
}
.editable-table-footer .editable-table-btn-wrap .editable-table-plus-btn-icon {
  cursor: pointer;
  color: #c6c6c6;
  font-weight: 700;
  font-size: 16px;
  margin-right: 5px;
}
.editable-table-footer .editable-table-btn-wrap .editable-table-plus-btn {
  padding: 0;
  width: auto;
}
.editable-table-header {
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #424242;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.editable-table-header .editable-table-header-extra {
  display: flex;
  align-items: center;
}
